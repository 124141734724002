
.calendarioDiv{
  margin: 30px 30px 30px 30px;
}

.time__Pick__calendar__divs__text{
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
}
.time__Pick__calendar__text{
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.time__Pick__calendar_ttitle{
  font-size: 1.3vw;
  margin: 20px 0 20px 0;
}
  
.time__Pick__calendar{
  background-color: rgba(200, 200, 200, 0.3);
  padding: 10px 0 30px  0;
  border-radius: 15px;
}
.Home_Work_Stage__insideDiv__icon__add{
  background-color: #41244A;
  width: 100%;
  color: white;
}

.calendarMartingtop{
  margin-top: 72px;
}
.time__Pick__calendar__delete{
  margin-top: 25px;
  background-color: rgba(200, 200, 200, 0.3);
  padding: 10px 0 10px  0;
  border-radius: 15px;
  display: flex;
    justify-content: center;
}

.Home_Work_Stage__insideDiv__icon__delete{
  color:#41244A;
  cursor: pointer;
  
}


.input-group-date-piker {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  justify-content: center;
  width: 100%;
}



//calendar css

.rbc-addons-dnd .rbc-row-content {
  pointer-events: none;
}
.rbc-addons-dnd .rbc-row-content .rbc-show-more,
.rbc-addons-dnd .rbc-row-content .rbc-event {
  pointer-events: all;
}
.rbc-addons-dnd .rbc-addons-dnd-over {
  background-color: rgba(0, 0, 0, 0.3);
}
.rbc-addons-dnd .rbc-events-container {
  pointer-events: none;
}
.rbc-addons-dnd .rbc-event {
  transition: opacity 150ms;
  pointer-events: all;
}
.rbc-addons-dnd .rbc-event:hover .rbc-addons-dnd-resize-ns-icon,
.rbc-addons-dnd .rbc-event:hover .rbc-addons-dnd-resize-ew-icon {
  display: block;
}
.rbc-addons-dnd.rbc-addons-dnd-is-dragging .rbc-event {
  pointer-events: none;
  opacity: .50;
}
.rbc-addons-dnd .rbc-addons-dnd-resizable {
  position: relative;
  width: 100%;
  height: 100%;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor {
  width: 100%;
  text-align: center;
  position: absolute;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:first-child {
  top: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:last-child {
  bottom: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor .rbc-addons-dnd-resize-ns-icon {
  display: none;
  border-top: 3px double;
  margin: 0 auto;
  width: 10px;
  cursor: ns-resize;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor {
  position: absolute;
  top: 4px;
  bottom: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor:first-child {
  left: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor:last-child {
  right: 0;
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor .rbc-addons-dnd-resize-ew-icon {
  display: none;
  border-left: 3px double;
  margin-top: auto;
  margin-bottom: auto;
  height: 10px;
  cursor: ew-resize;
}