.signature__canvas{
    width: 100%;
  
}
.popup-content {
    width: 90%;
}

.boton__control__paso__paso{
    
    background: none;
    border: none;
    outline: none;

}

@media only screen and (max-width: 600px) {
.Paso__paso__div {
    display: block;
    grid-template-columns: none;
    margin-top: 34px;
    text-align: center;
}
.paso__paso__div__central {
    display: block;
    align-items: center;
}
}