.btn__reportes_diario{
  width: 60%;
    border: none;
    margin-top: 2px;
    background: purple;
    color: white;
    border-radius: 5px;
    font-size: 1em;
}

.btn__reportes__diario__div{
width: 100%;
display: flex;
}

.img_reporte_diario_fecha{
  width: 12rem;
}
.fecha__reporte__diario{
  display: flex;
  position: absolute;
  top:  45%;
  width: 11rem;
  z-index: 2;
  color: white;
  font-size: 1.8rem;
  left: 1.5rem;
  justify-content: center;
}

.tabla__global__reportes td span{
  margin: 30px;
}

@media only screen and (max-width: 600px) {
.btn__reportes__diario__div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fecha__reporte__diario {
  display: flex;
  position: absolute;
  top: 45%;
  width: 11rem;
  z-index: 2;
  color: white;
  font-size: 1.8rem;
  left: 4.5rem;
  justify-content: center;
} 

.img_reporte_diario_fecha {
  width: 12rem;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

}