.avatarModeloPerfil{
    border-radius: 50%;
    position: relative;
    margin-top: 4%;
    margin-bottom: 4%;
 
}
.avatarModeloPerfilimg{
    object-fit: cover;
    width: 45%;
    position: relative;
    border-radius: 500px;
    right: 0;
    left: 0;
    margin: 0 auto;
    display: flex;
}
.avatarModeloPerfilimg, .avatarModeloPerfilimg > img{
    width: 200px;
    height: 200px
}

.avatarModeloPerfilimg{
    overflow: hidden;
    border-radius: 100%;
    border: 3px solid #eee;
    margin: auto;
}

.perfiltd{
    width: 20%;
}
.popover__Candado{
    margin-top: -13px;
    width: 3%;
}
.informacion_personal_td{
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 16rem;
}

.informacion_personal_td div{
    margin-top: 10px;
}

.informacion_personal_td img{
    margin-right: 10px;
}

.popover__Candado__cuentas {
    margin-top: -13px;
    width: 5%;
}
.popover__Candado__cuentas:hover {
    cursor: pointer;
}
.popover__Candado:hover {
    cursor: pointer;
}
.btn__perfil{
    width: 9rem;
    display: flex;
    height: 2rem;
    border: none;
    margin-top: 2px;
    background: #C8C8C8;
    color: white;
    border-radius: 5px;
    font-size: 1em;
    text-align: center;
    text-decoration: none;
    justify-content: center;
    align-content: center;
    text-decoration: none;
    
}
.btn__perfil:hover{
    text-decoration: none;
    color:white;
}
.nombre__estado__perfil__div{
    display: flex;
    width: 40%;
    border-radius: 5px;
    text-align: center;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #C8C8C8;
}

.Selecbox_estado{
    display: flex;
    align-content: center;
    align-items: center;
}

.Boton__buscar__perfil__modelos {
    width: 26%;
    border: none;
    /* margin-top: 2px; */
    background: purple;
    color: white;
    border-radius: 5px;
    font-size: 1em;
}

.btn__perfil_div {
    width: 100%;
    display: flex;
    justify-content: center;
    /* margin-bottom: 20px; */
}
.searchImg{
    position: absolute;
    left: -4.5em;
}

.reporte__div__principal__perfiil {
    
    margin: 40px;
    margin-top: 0px;
    border-radius: 25px;
}

.perfil__Search__text {
    width: 100%;
    background-color: rgba(200, 200, 200, 0.3);
    margin-top: 6px;
    border-radius: 5px;
    border-style: hidden;
}

.table-responsive-perfilVer {
    display: flex;
    width: 100%;
    overflow-x: auto;
    justify-content: center;
    -webkit-overflow-scrolling: touch;
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: purple;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: purple;
    border-color: purple;
}
.page-link:hover {
    z-index: 2;
    color: purple;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

@media only screen and (max-width: 600px) {
    .nombre__div__reporte {
        width: 100%;
        background-color: #40234a;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .nombre__estado__perfil__div {
        display: flex;
        width: 100% !important;
        border-radius: 5px;
        text-align: center;
        position: relative;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #C8C8C8;
    }


    .reporte__div__principal__perfiil {
        margin: 10px !important;
        margin-top: 0px;
        border-radius: 25px;
    }
    .searchImg {
        position: absolute;
        left: -4.1em;
        width: 31%;
        display: none;
    }
    .perfil__Search__text {
        width: 100%;
        background-color: rgba(200, 200, 200, 0.3);
        margin-top: 6px;
        border-radius: 5px;
        border-style: hidden;
        height: 4vh;
    }
    .Boton__buscar__perfil__modelos {
        width: 82%;
        border: none;
        margin-top: 11px;
        background: purple;
        color: white;
        border-radius: 5px;
        font-size: 1em;
    }
}
