.right-submenu_reporte_pago{
    height: 1.3vw;
    border-radius: 5px;
    border: none;
    width: 95%;
}

.Selecbox_fechas{
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 8px;
}

.Boton__reportes__pagos__modelos{
    width: 15%;
    border: none;
    margin-top: 2px;
    background: purple;
    color: white;
    border-radius: 5px;
    font-size: 1em;
}
.btn__reportes_div{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.informe__modelo__div__container{
    display: grid;
   grid-template-columns: 50% 25% 25%;
    grid-gap: 1px;
    width: 60%;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: rgba($color: #C8C8C8, $alpha: 0.3);
    border-radius: 10px;
    margin-top: 20px;
}
.div__one__informe__modelo{
    display: grid;
    grid-template-rows: 70% 4% 4% 4% 4% 14%;
    text-align: center;
}
.div__third__informe__modelo{
    position: relative;
    display: grid;
    top: 3%;
    grid-template-rows: 80% 20%;
}

.div__third__informe__modelo__dolar__div{
    display: grid;
    grid-template-columns: 10% 90%;
}

.div__third__informe__modelo__dolar__div__dolar__signo{
    font-size: 2.8rem;
    display: flex;
    justify-content: center;
    color:#C8C8C8;
    font-weight: 200;
}

.div__third__informe__modelo__dolar__div__dolar__detalle{
    display: grid;
    grid-template-rows: 15%;
    position: relative;
    top: 12%;
}


.informes__pago__arrow{
    color: #C8C8C8;
}
.logoReportImg{
    width: 18rem;
    position: relative;
    left: 0;
    right: 0;
    top: 2rem;
    bottom: 0;
    margin: 0 auto;
  
}

.tabla-global-repore-modelo td{
    border: 1px solid #C8C8C8;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    color: #212529;
}

@media only screen and (max-width: 600px) {

    .right-submenu_reporte_pago {
        height: 5.3vh;
        border-radius: 5px;
        border: none;
        width: 95%;
    }
    
    
    .Boton__reportes__pagos__modelos {
        width: 41%;
        border: none;
        margin-top: 2px;
        background: purple;
        color: white;
        border-radius: 5px;
        font-size: 1em;
    }



    .informe__modelo__div__container {
        display: grid;
        grid-template-columns: none;
        grid-gap: 1px;
        width: 100%;
        position: relative;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: rgba(200, 200, 200, 0.3);
        border-radius: 10px;
        margin-top: 20px;
    }


    .div__one__informe__modelo {
        display: grid;
        grid-template-rows: none;
        text-align: center;
    }


    .logoReportImg {
        width: 34%;
        position: relative;
        left: 0;
        right: 0;
        top: 0; 
        bottom: 0;
        margin: 0 auto;
    }


    .div__third__informe__modelo {
        position: relative;
        display: flex;
         top: 0%; 
         grid-template-rows: none; 
    }


    .div__third__informe__modelo__dolar__div {
         display: grid;
       grid-template-columns: none; 
    }


    .div__third__informe__modelo__dolar__div__dolar__detalle {
        display: grid;
       grid-template-rows:none; 
        position: relative;
         top: 12%; 
    }

}
