
$checkbox-size: 1.1rem;
input[type="checkbox"].checkbox {
  appearance: none;
  width: $checkbox-size;
  height: $checkbox-size;
  position: relative;
  margin-right: 12px;
  color:red !important;
  
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: -webkit-rgba(white, 0);
    background-color: white;
    border-radius: 3px;
    box-shadow: inset 0 0 0 2px none;
    font: {
      size: 0rem;
      weight: bolder;
    }
    line-height: $checkbox-size;
    text-align: center;
    transition:
      background-color 0ms 0ms ease,
      color 0ms 0ms ease,
      font-size 0ms 0ms ease;
  }
  
  &:checked::after {
    content: "\02714";
    color: #C8C8C8;
    background-color: none;
    font-size: 0.7em;

  }
}

.Permisos_Usuarios_Stage__insideDiv__icon__add {
  color: #41244A;
  cursor: pointer;
  /* right: 28px; */
  /* margin-top: 3px; */
}
.Permisos_Usuarios_Stage__insideDiv__icon__add_permisos {
  color: #41244A;
  cursor: pointer;
  margin-left: 2%;
  top: 11%;
}

.Permisos_Usuarios_Stage__insideDiv__icon__add_permisos img{
  width: 50%;
}

.pageTitulos {
  font-size: 4rem;
  color: #c8c8c8;
  text-align: left;
  margin-left: 36px;
  /* margin-top: 62px; */
}

.reporte__div__principal__permisos {
  background-color: rgba(200, 200, 200, 0.3);
  margin: 40px;
  margin-top: 0px;
  border-radius: 25px;
  padding-bottom: 20px;
}



.reporte__div__secundario__permisos {
  margin: 40px;
  margin-top: 0px;
  border-radius: 25px;
}


@media only screen and (max-width: 600px) {

  .border__monitor {
    border: solid 0.5px;
    /* margin: 3px; */
    border-radius: 10px;
    margin: 0;
    margin-bottom: 15px;
  }

}

