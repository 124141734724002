.nombre__reporte__container__cuentas__modelo {
    display: flex;
    width: 40%;
    border-radius: 5px;
    text-align: center;
    position: relative;
    left: 0;
    right: 0;
    /* margin: 0 auto; */
    background-color: #C8C8C8;
    margin-top: 15px;
    margin-left: 36px;
}
.Cuentas__back__img{
    width: 95%;
    margin-left: 0px;
    position: absolute;
    margin-top: 4.3%;
    /* left: 49px; */
    z-index: -1;
}

.btn__reportes_page_cuentas{
    width: 100%;
    border: none;
    margin-top: 32px;
    height: 20%;
    background: purple;
    color: white;
    border-radius: 5px;
    font-size: 1em;
}





.centrar__items{
    display: flex;
    justify-content: center;
    align-content: center;
}

.reporte__div__principal__cuentas{
  
    margin: 40px;
    margin-top: 0px;
    border-radius: 25px;
}

.contenedor__cuentas__Modelo{
    margin-top: 13.1%;
    border-radius: 20px;
    min-height: 15vw;
    padding-bottom: 26px;
    background: #eeee;
}



.chaturbate__cuenta__div{
    position: relative;
    display: grid;
    grid-template-columns: auto 71%;
}

.circulo {
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	background: #C8C8C8;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
  padding:3%;
  margin-left: 50px;
}

.circulo > h2 {
	font-family: sans-serif;
	color: white;
	font-size: 1.2rem;
    font-weight: bold;
    margin-bottom:0 !important;
}


.div__checkbox__input_cuentas{
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid #000;
    margin-right: 10px;
}

.btn__reportes_page_transfer{
    width: 100%;
    border: none;
    margin-top: 5px;
    /* height: 20%; */
    background: purple;
    color: white;
    border-radius: 5px;
    font-size: 1em;
}


.div__checkbox__input{
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}


//sjfhafsjfsa

$checkbox-size: 1.1rem;

input[type="checkbox"].checkbox {
  appearance: none;
  width: $checkbox-size;
  height: $checkbox-size;
  position: relative;
  margin-right: 12px;
  color:red !important;
  
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: -webkit-rgba(white, 0);
    background-color: white;
    border-radius: 3px;
    box-shadow: inset 0 0 0 2px none;
    font: {
      size: 0rem;
      weight: bolder;
    }
    line-height: $checkbox-size;
    text-align: center;
    transition:
      background-color 350ms 0ms ease,
      color 350ms 250ms ease,
      font-size 350ms 250ms ease;
  }
  
  &:checked::after {
    content: "\02714";
    color: #C8C8C8;
    background-color: none;
    font-size: 0.7em;
  }
}
.input__cuentas__modelos{
    border: none;
    width: 70%;
    margin-top: 5px;
    margin-left: 50px;
    border-radius: 5px;
}


.Img__Checkboxs{
    object-fit: contain;
    width: 1.2em;
    height: 1.3em;
    margin-right: 10px;
}

.addBtnImg{
  
float:right;
cursor: pointer;
margin-top: 46px;
}


.btn__Cuentas__ver__todas{
    width: 20%;
    height: 1.2vw;
    border: none;
    margin-top: 14px;
    margin-left: 51px;
    background: #c8c8c8;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.btn__cuentas__modelo__div{
display: flex;
    justify-content: space-between;

}


.Cuentas__back__img__icon{
    position: absolute;
    width: 43%;
    left: -5%;
    top: 24px;
}

.Cuentas__back__img__icon__strip{
    position: absolute;
    width: 31%;
    left: 17px;
    top: 4vw;
}

.Cuentas__back__img__icon__cam4{
    position: absolute;
    width: 31%;
    left: 17px;
    top: 6vw;
}

.Cuentas__back__img__icon__streamate{
    position: absolute;
    width: 31%;
    left: 17px;
    top: 3vw;


}

.check_box_acc{
    display: block;
    float: right;
    margin-top: -18px;
}
.input__cuentas__modelos {
    border: none;
    width: 80%;
    margin-top: 5px;
    margin-left: 50px;
    border-radius: 5px;
}
.size__acc__action {
    width: 1.2vw;
    height: 1.2vw;
    color: #40234a;
}


.d-flex{
    justify-content: center;
}
@media only screen and (max-width: 600px) {

    .Cuentas__back__img__icon {
        position: absolute;
        width: 43%;
        left: -5%;
        top: 9px;
    }

    .Cuentas__back__img {
        width: 100%;
        margin-left: 0px;
        position: absolute;
        margin-top: 4.3%;
        /* left: 49px; */
        z-index: -1;
    }

    .size__acc__action {
        width: 9vw;
        height: 10vw;
    }

    .nombre__reporte__container__cuentas__modelo {
        display: flex;
        width: 79%;
        border-radius: 5px;
        text-align: center;
        position: relative;
        left: 0;
        right: 0;
        /* margin: 0 auto; */
        background-color: #C8C8C8;
        margin-top: 15px;
        margin-left: 36px;
    }

    .chaturbate__cuenta__div {
        position: relative;
        display: grid;
        grid-template-columns: auto 107%;
    }


    .div__checkbox__input {
        margin-top: 0px;
        display: block;
        justify-content: space-between;
        padding: 5px;
    }

    .input__cuentas__modelos {
        border: none;
        width: 100%;
       margin-top: 0px;
        margin-left: 0px;
        border-radius: 5px;
       padding: 11px; 
    }
    .btn__Cuentas__ver__todas {
        width: 60%;
        height: 4vh;
        border: none;
        margin-top: 14px;
        margin-left: 5px;
        background: #c8c8c8;
        color: white;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-content: center;
    }


    .circulo {
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background: #C8C8C8;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 3%;
        margin-left: 8px;
        bottom: -64px;
    }

    .Cuentas__back__img__icon__strip {
        position: absolute;
        width: 31%;
        left: 9px;
        top: 6vw;
    }

    .Cuentas__back__img__icon__cam4 {
        position: absolute;
        width: 31%;
        left: 12px;
        top: 10vw;
    }
    .Cuentas__back__img__icon__streamate {
        position: absolute;
        width: 31%;
        left: 7px;
        top: 6vw;
    }
}