.search-container{
display: flex;
justify-content: center;
align-items: center;
  >.search-menu-container{
    width: 60%;
    position: relative;
    >.search-menu-suggest{

      >.srchList{
        background-color: #ddd;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    margin-top: 0px;
    width: 100%;
    z-index: 2;
      }

    }
  }

}

.prestamos_g_butons{
  width: 100%;
  margin-top: 6px;
  border-radius: 5px;
  border-style: hidden;
  border:solid 1px black;
}

.close-lateral{
  position: absolute;
  top: 4rem;
  left: 1rem;
  font-size: 25px;
}

  @media only screen and (max-width: 600px) {

  .btn_action {
    width: 10vw;
    height: 9vw;
    /* size: 30vw; */
    /* font-size: 62vw; */
}

.tituloPaginas {
  font-size: 2.5rem !important;
  color: #c8c8c8;
  text-align: left;
  margin-left: 36px;
  margin-top: 61px;
}

.collapsed-prestamo {
  width: 100%;
  transition: 1s;
}

  }