$background: #e74c3c;
$select-color: #40234A;
$select-background: #c0392b;



.btnactualizarreport{
  background: none;
  margin: 5px;
  background-color: purple;
  color: white;
  border: 0 none;
  border-radius: 0px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  padding: 10px 10px;
  box-sizing: border-box;
  width: 100%;
  margin: 1.5%;
  font-size: 1rem;
}


.main-content {
  margin-top: 39px;
    margin-left: 215px;
    padding: 0;
}

.reporte__div__principal{
  background-color: rgba(200, 200, 200, 0.3);
  margin: 40px;
  margin-top: 0px;
  border-radius: 25px;
}

.select__places {
  position: absolute;
  background-color: white;
  left: 0;
  right: 0;
  z-index: 0;
  border-radius: 3px;
  position: relative;
  margin-top: 5px;

}

.option-values{
  background-color:#CCCCCC;
  color: white;
  text-align: center;
}

.option-values:hover{
  background-color: black;
}
.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}


.select__places:hover::before {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(255, 255, 255, 0.2);
}

.right-submenu{
  width: 100%;
}

.react-date-picker{
  width: 100%;
  margin-top: 5px;
}

.btn__reportes_page{
  width: 100%;
    border: none;
    margin-top: 2px;
    background: purple;
    color: white;
    border-radius: 5px;
    font-size: 1em;
}

.btn__reportes_div{
width: 100%;
}

.center__reportes__botones{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;

}

option:hover {
 color: $select-color;
}


select:checked {
  background: #BB4687;
  color: $select-color;
}

.tabla-global thead{
  color: #828282;
  border-bottom-style: hidden;
  border-top-style: hidden;
}

.tabla-global tbody{
 border-radius: 10px;
}
