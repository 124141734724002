$checkbox-size: 2rem;

input[type="checkbox"] {
  appearance: none;
  width: $checkbox-size;
  height: $checkbox-size;
  position: relative;
  
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: rgba(white, 0);
    background-color: white;
    border-radius: 3px;
    box-shadow: inset 0 0 0 2px grey;
    font: {
      size: 0rem;
      weight: bolder;
    }
    line-height: 0.8rem;
    text-align: center;
    transition:
      background-color 350ms 0ms ease,
      color 350ms 250ms ease,
      font-size 350ms 250ms ease;
  }
  
  &:checked::after {
    content: "•";
    color: #C8C8C8;
    background-color: white;
    font-size: 4.5rem;
  }
}


.centrar__objetos{
    display: flex;
    justify-content: center;
    align-items: center;
}

.invetario__btn{width: 50%;
    border: none;
    margin-top: 2px;
    background: purple;
    color: white;
    border-radius: 5px;
    font-size: 1em;}

    .react-date-picker {
        width: 100%;
        margin-top: 5px;
        border: solid 1px;
    }