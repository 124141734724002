
.circuloPasoPaso {
	width: 7rem;
	height: 7rem;
	border-radius: 50%;
  background-color: rgba(200, 200, 200, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
  padding:3%;
  margin-left: 50px;
}

.Paso__paso__div .circuloPasoPaso  h2 {
	font-family: sans-serif;
	color: white;
	font-size: 7rem;
    font-weight: bold;
    margin-bottom:0 !important;
}

.Paso__paso__div{
  display: grid;
  grid-template-columns: 15% 20% 30%;
  margin-top: 34px;}

  .Paso__paso__div h2{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #C8C8C8;
  }
.paso__paso__div__control{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


 .circuloPasoPaso__control {
  width: 3rem;
  height: 3rem;
	border-radius: 50%;
  background-color: rgba(200, 200, 200, 0.3);
	display: flex;    
	justify-content: center;
	align-items: center;
	text-align: center;
  padding:3%;
  margin: 0 10px;
}


.circuloPasoPaso__control  h2 {
	font-family: sans-serif;
	color: white;
	font-size: 2rem;
    font-weight: bold;
    margin:0  10px 0 10px;
  
}
.paso__paso__div__central{
  display: flex;
  align-items: center;
}