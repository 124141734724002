.searchImgCorreo {
    position: absolute;
    left: -1.5em;
    width: 7%;
}

.perfil__Search__text__correo {
    width: 60%;
    margin-top: 6px;
    border-radius: 5px;
    margin-left: 5%;
    border-style: hidden;
    display: flex;
    justify-content: center;
}

.input__correo__search {
    margin-top: 0px;
    width: 100%;
    z-index: 1;
    padding-right: 0;
    padding-left: 0;

}


.nombre__reporte__container__correo{
    display: flex;
    width: 90%;
    border-radius: 5px;
    text-align: center;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #C8C8C8;
    margin-bottom: 15px;
}


.nombre__correo__container {
    display: flex;
    width: 73%;
    border-radius: 5px;
    text-align: center;
    position: relative;
    left: 0;
    right: 0;
    margin-left: 7%;
    background-color: #C8C8C8;
    margin-bottom: 15px;
}

.div__personal__correo__info {
    display: grid;
    grid-template-columns: auto 90%;
    grid-gap: 5px;
    margin-top: 10px;
}

.div__text__correo__info {
    display: grid;
    grid-template-columns: 10% 50% auto;
    grid-gap: 5px;
    margin-top: 10px;
}

.input__modificiar_informacion_correos {
    border: none;
    border-radius: 5px;
    font-weight: 500;
    width: 78%;
}


.input__correo {
    border: none;
    border-radius: 5px;
    font-weight: 500;
    width: 100%;
}

.informacion_correos_modificar_info_modelo {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 6rem;
    float: right;
    width: 100%;
    margin-top: 4%;
    margin-bottom: 4%;
}


.Boton__crear__correos {
    width: 15%;
    border: none;
    margin-top: 2%;
    background: purple;
    color: white;
    border-radius: 5px;
    font-size: 1em;
    margin-left: 7%;
}

.srchList__correo {
    background-color: #ddd;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    margin-top: 0px;
    margin-left: 5%;
    width: 60%;
    text-decoration: none;
    z-index: 2;
    list-style-type: none;
    cursor: pointer;
}



@media only screen and (max-width: 600px) {
.searchImgCorreo {
    position: absolute;
    left: -1.5em;
    width: 7%;
    display: none;
}



.perfil__Search__text__correo {
    width: 100%;
    margin-top: 6px;
    border-radius: 5px;
    margin-left: 0%; 
    border-style: hidden;
    display: flex;
    justify-content: center;
}
.text__rigth {
    text-align: left;
}


.nombre__correo__container {
    display: flex;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    position: relative;
    left: 0;
    right: 0;
    margin-left: 0;
    background-color: #C8C8C8;
    margin-bottom: 0;
    margin-top: 5px;
}
.div__text__correo__info {
    display: block;
    grid-template-columns: none;
    grid-gap: 5px;
    margin-top: 10px;
}
.informacion_correos_modificar_info_modelo {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: auto;
    float: right;
    width: 100%;
    margin-top: 0;
    margin-bottom: 4%;
}

.div__personal__correo__info {
    display: block;
    grid-template-columns: auto 90%;
    grid-gap: 5px;
    margin-top: 10px;
}

.input__modificiar_informacion_correos {
    border: none;
    border-radius: 5px;
    font-weight: 500;
    width: 100%;
}


.Boton__crear__correos {
    position: relative;
    border: none;
    display: flex;
    justify-content: center;
    width: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-top: 6%;
    background: purple;
    color: white;
    border-radius: 5px;
    font-size: 1em;
    /* margin-left: 7%; */
}

}