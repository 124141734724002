.btn__reportes__div__pagos{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.img__nomina__modelos{

  width: 11vh;
  position: relative;
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.img__nomina__monitores{

  width: 13vh;
  position: relative;
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.img__nomina__ganancias{

  width: 13vh;
  position: relative;
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.paddingPagos{
padding: 40px;
}
.lista__fechas__nomina__modelos{
  height: auto;
  margin-top: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
  width: 100%;
}

.btn__nemoina{
  width: 100%;
  border: none;
  margin-top: 2px;
  background: purple;
  color: white;
  border-radius: 5px;
  font-size: 1em;
  text-align: center;
  text-decoration: none;
}

.btn__nemoina:hover{
  color: white;
  text-decoration: none;
}


