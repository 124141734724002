#viewer {
  width: 100%;
  height: 100vh;
  padding: 50px;

}
.div__room__labels{
  display: grid;
  grid-template-columns: 70% auto;
  align-items: center;
}

.assign__rooms__btn{
  background: purple;
  border: none;
  color: white;
  width: 60%;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
}

.clean__button{
  color: red;
    cursor: pointer;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
}