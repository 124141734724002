.reportes__img__plataformas__Chaturbate{
    width: 100%;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.reportes__img__plataformas__Stripchat{
    width: 100%;
    position: relative;
    top: 7px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.reportes__img__plataformas__cam4{
    width: 100%;
    top: 11.5px;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.reportes__img__plataformas__Streamate{
    width: 100%;
    position: relative;
    top: 7px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.reportes__img__plataformas__Total{
    width: 24%;
    position: relative;
    top: 7px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;

}

.laber__plataforma__1{
    position: absolute;
    right: 21px;
    top: 27%;
    font-size: 1em;
    height: 23%;
    width: 52%;
    border-radius: 5px;
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.laber__plataforma__2{
    position: absolute;
    right: 21px;
    top: 54%;
    font-size: 1em;
    height: 23%;
    width: 52%;
    border-radius: 5px;
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.laber__plataforma__Total__1{
    position: absolute;
    right: 38.9%;
    top: 30%;
    font-size: 1em;
    height: 23%;
    width: 13.7%;
    border-radius: 5px;
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.laber__plataforma__Total__2{
    position: absolute;
    right: 38.8%;
    top: 60%;
    font-size: 1em;
    height: 23%;
    width: 13.7%;
    border-radius: 5px;
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.label__plataforma__text{
    margin-left: 5px;
    color:#828282;
}

.triangulo__report__container{
    width: 0; 
     height: 0; 
     border-left: 2vh solid #40234A;
     border-top: 1vw solid transparent;
     border-bottom: 1vw solid transparent;
}

.nombre__div__reporte{
    width: 60%;
    background-color: rgba($color: #40234A, $alpha: 1.0);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

}
.nombre__reporte__container{
    display: flex;
    width: 40%;
    border-radius: 5px;
    text-align: center;
    position: relative;
    left: 0;
    right: 0;
    margin:  0 auto;
    background-color: #C8C8C8;
    margin-bottom: 15px;
    
}
.nombre__modelo__div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}




@media only screen and (max-width: 600px) {


    .nombre__reporte__container {
        display: flex;
        width: 70%;
        border-radius: 5px;
        text-align: center;
        position: relative;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #C8C8C8;
        margin-bottom: 15px;
      }
      
      .laber__plataforma__Total__2 {
        position: absolute;
        right: 7.8%;
        top: 60%;
        font-size: 1em;
        height: 23%;
        width: 47.7%;
        border-radius: 5px;
        background-color: white;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .laber__plataforma__Total__1 {
        position: absolute;
        right: 7.9%;
        top: 30%;
        font-size: 1em;
        height: 23%;
        width: 47.7%;
        border-radius: 5px;
        background-color: white;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      
      .reportes__img__plataformas__Total {
        width: 100%;
        position: relative;
        top: 7px;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
      }

}

