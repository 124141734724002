.navbar-global {
  background: rgba(65,36,74,1);
  background: -moz-linear-gradient(left, rgba(65,36,74,1) 0%, rgba(65,36,74,1) 38%, rgba(65,36,74,1) 46%, rgba(205,15,91,1) 71%, rgba(205,15,91,1) 85%, rgba(205,15,91,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(65,36,74,1)), color-stop(38%, rgba(65,36,74,1)), color-stop(46%, rgba(65,36,74,1)), color-stop(71%, rgba(205,15,91,1)), color-stop(85%, rgba(205,15,91,1)), color-stop(100%, rgba(205,15,91,1)));
  background: -webkit-linear-gradient(left, rgba(65,36,74,1) 0%, rgba(65,36,74,1) 38%, rgba(65,36,74,1) 46%, rgba(205,15,91,1) 71%, rgba(205,15,91,1) 85%, rgba(205,15,91,1) 100%);
  background: -o-linear-gradient(left, rgba(65,36,74,1) 0%, rgba(65,36,74,1) 38%, rgba(65,36,74,1) 46%, rgba(205,15,91,1) 71%, rgba(205,15,91,1) 85%, rgba(205,15,91,1) 100%);
  background: -ms-linear-gradient(left, rgba(65,36,74,1) 0%, rgba(65,36,74,1) 38%, rgba(65,36,74,1) 46%, rgba(205,15,91,1) 71%, rgba(205,15,91,1) 85%, rgba(205,15,91,1) 100%);
  background: linear-gradient(to right, rgba(65,36,74,1) 0%, rgba(65,36,74,1) 38%, rgba(65,36,74,1) 46%, rgba(205,15,91,1) 71%, rgba(205,15,91,1) 85%, rgba(205,15,91,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#41244a', endColorstr='#cd0f5b', GradientType=1 );
}

.welcometext{
  display: flex;
  justify-content: center;
  color:#828282;
}

.fixed-top {
  position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1 !important;
}

.navbar-primary-bg {
  display: none;
}
.profil_img{
  object-fit: cover;
  width: 45%;
  position: relative;
  border-radius: 500px;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
}
.profil_img, .profil_img > img{
  width: 150px;
  height: 150px
}

.profil_img{
  overflow: hidden;
  border-radius: 100%;
  border: 3px solid #eee;
  margin: auto;
}

.navbar-global .navbar-brand {
  color: white;
}

.navbar-global .navbar-user > li > a
{
  color: white;
}

.navbar-primary {
  background-color: #41244A;
  bottom: 0px;
  left: 0px;
  position: fixed;
  top: 39px;
  width: 215px;
  z-index: 8;
  overflow-y: auto;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  
}

.navbar-primary::-webkit-scrollbar {
  width: 5px;
}
 
.navbar-primary::-webkit-scrollbar-track {
  background: #ddd;
}
 
.navbar-primary::-webkit-scrollbar-thumb {
  background: #CD0F5B; 
}

.navbar-primary.collapsed {
  width: 60px;
}

.navbar-primary.collapsed .glyphicon {
  font-size: 22px;
}

.navbar-primary.collapsed .nav-label {
  display: none;
}


.btn-expand-collapse {
    position: relative;
    display: block;
    left: 0px;
    bottom:0;
    width: 100%;
    padding: 4px 0;
    color: #41244A;
    text-align: center;
    bottom: 0px;
    margin-top: 20px;
}

.btn-expand-collapse:hover,
.btn-expand-collapse:focus {
  background-color: rgba(205, 15, 91, 0.2);
    opacity: 0.8;    
    color: white;
}



.navbar-primary-menu,
.navbar-primary-menu li {
  margin:0; padding:0;
  list-style: none;
}

.navbar-primary-menu li a {
  display: block;
  padding: 10px 18px;
  text-align: left;
  //border-bottom:solid 1px #444;
  color: #ccc;
}

.navbar-primary-menu li a:hover {
  background-color: rgba(205, 15, 91, 0.2);
    //opacity: 0.2; 
    text-decoration: none;   
  color: white;
}

.navbar-primary-menu li a .glyphicon {
  margin-right: 6px;
}

.navbar-primary-menu li a:hover .glyphicon {
  color: orchid;
}

.collapsed + .main-content {
  margin-left: 60px;
}

.menuSeparator{
  color: #828282;
  font-size: 1.5rem;
  text-align: left;
  margin-top: 30px;
  margin-left: 18px;

}
.icon_color{
  color:#CD0F5B;
  margin-top: -4px;
  margin-right: 17px;
}
nav a:active{
  background-color: white;
}


.item{
    border-top: 1px solid #ef584a;
    overflow: hidden;
}
.btn{
    display: block;
    padding: 15px 20px;
    background-color: #ff6f61;
    color: #fff;
    position: relative;

}
.btn:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right:8px solid transparent;
    border-top:10px solid #ff6f61;
    right: 15px;
    bottom: -10px;
    z-index: 9;
    
}
.btn i {
    margin-right: 10px;
}
.subMenu{
    background: #273057;
    overflow: hidden;
    transition: max-height 0.7s;
    max-height: 0;
}
.subMenu a{
    display: block;
    padding: 15px 20px;
    color: #fff;
    font-size: 14px;
    border-bottom: 1px solid #394c7f;
    position: relative;
    
}
.subMenu a:before{
    content: '';
    opacity: 0;
    transition: opacity 0.3s;

}
.subMenu a:hover:before{
    content: '';
    position: absolute;
    height: 0;
    width: 6px;
    left: 0;
    top:0;
    opacity: 1;
    /* background-color: #d8d824; */
    border-top: 24px solid transparent;
    border-left: 11px solid #fcdc29;
    border-bottom: 24px solid transparent;
}
.subMenu a:after{
    content: '';
    opacity: 0;
    transition: opacity 0.3s;

}
.subMenu a:hover:after{
    content: '';
    position: absolute;
    height: 0;
    width: 6px;
    right: 0px;
    top:0;
    opacity: 1;
    /* background-color: #d8d824; */
    border-top: 24px solid transparent;
    border-right: 11px solid #fcdc29;
    border-bottom: 24px solid transparent;
}
.subMenu a:hover{
    background: #273057;
    background: -moz-linear-gradient(top, #273057 0%, #273057 50%, #394c7f 51%, #394c7f 100%);
    background: -webkit-linear-gradient(top, #273057 0%,#273057 50%,#394c7f 51%,#394c7f 100%);
    background: linear-gradient(to bottom, #273057 0%,#273057 50%,#394c7f 51%,#394c7f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#273057', endColorstr='#394c7f',GradientType=0 );
    transition: all 0.3s;
    border-bottom: 1px solid #394c7f;
}
.subMenu a:last-child{
    border:none;
}
.item:target .subMenu{
    max-height: 10em;
}


//-------------------s

ul.list {
  display: none;
      margin-bottom: 6px;
    margin-left: 23px;

}
.list li{
  margin-top: 3px;
  color: #D9D9D9;
}
.list li::before {
  content: '\2022';
  color: #CD0F5B;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}
.list.show {
  display: block;
}

.submenu__items{
  margin: 0;
  padding: 0;
  display: flex;
}


.submenu__items a{
  padding: 0 !important;

}

@media only screen and (max-width: 600px) {
  .collapsed + .main-content {
     margin-left: 0 ;
  }
  
  .main-content {
    margin-top: 39px;
     margin-left: 0 !important;
    padding: 0;
    width: 100%;
  }
  .btn-expand-collapse {
    position: fixed;
    display: flex;
    left: 0px;
    bottom: 0;
    width: 100%;
    padding-left: 4px !important;
    color: #41244A;
    /* text-align: center; */
    bottom: 0px;
    z-index: 2;
    height: 0;
    align-items: center;
    top: 18px;
}
  
.navbar-primary.collapsed {
  width: 0;
}

.navbar-primary-bg.collapsed {
  width: 0;
}
.navbar-primary-bg{
  display: block;
  background-color: rgba(205, 15, 91, 0.2);
  bottom: 0px;
  right: 0px;
  position: fixed;
  top: 40px;
  width: 33%;
  z-index: 0;
  overflow-y: auto;
  transition: all 0.2s ease-in-out;
}


.navbar-primary {
  background-color: #41244A;
  bottom: 0px;
  left: 0px;
  position: fixed;
  top: 40px;
  width: 67%;
  z-index: 8;
  overflow-y: auto;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  
}

}

