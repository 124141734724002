.nombre__reporte__container__multas__total {
  display: flex;
  width: 40%;
  border-radius: 5px;
  text-align: center;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #C8C8C8;
  margin-top: 15px;
}