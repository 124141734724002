.btn_crear_monitor{width: 50%;
  border: none;
  margin-top: 2px;
  background: purple;
  color: white;
  border-radius: 5px;
  position: relative;
  font-size: 1em;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  text-align: center;
  justify-content: center;}


.border__monitor{
  border: solid 0.5px;
    /* margin: 3px; */
    border-radius: 10px;
    margin: 4%;
}

.informacion_users_modificar_info_monitor {
  display: flex;
  flex-flow: column;
  justify-content: center;
  float: right;
  width: 100%;
 
}

.Home_Work_Stage__insideDiv__icon__x {
  color: black;
  cursor: pointer;
  margin: 15px;
}