.informe__modelo__div__container_pagonomina_monitores{
  display: grid;
  grid-template-columns: 42% auto;
  grid-gap: 1px;
  width: 100%;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-bottom: 15px;
  margin-top: 10px;
  background-color: rgba(200, 200, 200, 0.3);
  border-radius: 10px;
}
.logo__Report__Img__monitores{
  width: 11rem;
    position: relative;
    left: 0;
    right: 0;
    top: 2rem;
    bottom: 0;
    margin: 0 auto;
}
