

  .input {
    margin-top: 0px;
    width: 100%;
    z-index: 0 !important;
    padding-right: 0;
    padding-left: 0;
  }

  .srchList {
    background-color: #ddd;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    margin-top: 0px;
    width: 100%;
    z-index: 2;
    //overflow-y: scroll;
  }
  .input__autocomplete__Text{
    width: 100%;
  }

  .input__autocomplete__SuggestionText{
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  
  
  .srchList li {
    list-style-type: none;
    color: #363636;
  }

  .srchList li:hover {
  cursor: pointer;
  background-color: white;
  }
  
  .tituloPaginas{
    font-size: 4rem;
    color: #c8c8c8;
    text-align: left;
    margin-left: 36px;
    margin-top: 62px;
  }

  .tituloPaginasPequeño{
    font-size: 2.5rem;
    color: rgba(200, 200, 200, 0.3);
  }
  .prestamosGlobar__text{
    width: 100%;
    margin-top: 6px;
    border-radius: 5px;
    border-style: hidden;
  }
  .todos__prestamos__div{
    overflow-y: scroll;
    height: 34vh;
  }
  .loans__icon__x{
    display: flex;
    justify-content: center;
  }
  $size_btn_action:1vw;
  .btn_action{
    width: $size_btn_action;
    height: $size_btn_action;
  }


  @media only screen and (max-width: 600px) {

  .btn_action {
    width: 10vw;
    height: 9vw;
    /* size: 30vw; */
    /* font-size: 62vw; */
}

  }