.div__number__container{    position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  top: 25%;
  font-size: 5rem;
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 2px black;}

  .margin__box__rooms{    margin: 50px 0 50px 0}

  .icom__room_img{
    position: absolute;
    bottom: 5%;
    right: 25%;
    width: 13%;
  }