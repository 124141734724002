.nombre__reporte__container__modificarinfo {
  margin-left: 47px;
  margin-bottom: 14px;
  display: flex;
  width: 40%;
  border-radius: 5px;
  text-align: center;
  position: relative;
  left: 0;
  right: 0;
  /* margin: 0 auto; */
  background-color: #C8C8C8;
  /* margin-top: 15px; */
}

#foto_perfil_modelo{
  display: none;
}
#img_modelo_perfil_add{
  display: none;
}
.foto_perfil_modelo_label{
  position: relative;
    display: flex;
    justify-content: center;
    margin-top: -13px;
    cursor: pointer;
}

.foto_perfil_modelo_label__add{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.informacion_personal_modificar_info_modelo {
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 16rem;
  float: right;
  width: 100%;
}

.div__personal__modificar__info{
  display: grid;
  grid-template-columns: auto 80%;
  grid-gap: 5px;
  margin-top: 10px;
}

.div__personal__modificar__info__derecha{
  display: grid;
  grid-template-columns: 25% auto;
  grid-gap: 5px;
  margin-top: 10px;
}
.informacion_personal_modificar_info div{
  margin-top: 10px;
}

.input__modificiar_informacion_modelo{
  border: none;
  border-radius: 5px;
  font-weight: 500;
}
.titulo__infor__contable{
  font-size: 3rem;
    color: white;
    margin-left: 36px;
    background-color: #CD0F5B;
    margin-top: 0;
    width: 23%;
    border-radius: 10px;
    text-align: center;
}


.img__add__perfil__modelo {
  color: GAINSBORO;
  cursor: pointer;
}

.avatarModeloimgGaleria{
  position: relative;

}
.avatarModeloimgGaleria{
  object-fit: cover;
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
}
.avatarModeloimgGaleria, .avatarModeloimgGaleria > img{
  width: 100%;
    height: 25vh;
}

.avatarModeloimgGaleria{
  overflow: hidden;
  border: 3px solid #eee;
  margin: auto;
}


@media only screen and (max-width: 600px) {

.nombre__reporte__container__modificarinfo {
  margin-left: 0;
  margin-bottom: 11px;
  display: flex;
  width: 90%;
  border-radius: 5px;
  text-align: center;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #C8C8C8;
  margin-top: 5px;
  margin-bottom: 7px;
}


.titulo__infor__contable {
  font-size: 3rem;
  position: relative;
  color: white;
  /* margin-left: 36px; */
  background-color: #CD0F5B;
  margin-top: 0;
  width: 80%;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.informacion_personal_modificar_info_modelo {
  display: block;
  flex-flow: column;
  justify-content: center;
  height: auto;
  float: right;
  width: 100%;
}
.input__modificiar_informacion_modelo {
  border: none;
  border-radius: 5px;
  font-weight: 500;
  width: 100%;
}
.div__personal__modificar__info {
  display: block;

}

.div__personal__modificar__info__derecha {
  display: block;

}


.Boton__buscar__perfil__modelos {
  width: 70%;
  border: none;
  margin-top: 8px;
  background: purple;
  color: white;
  border-radius: 5px;
  font-size: 1em;
  margin-bottom: 15px;
}

.triangulo__report__container {
  width: 0;
  height: 0;
  display: flex;
  border-left: 2vh solid #40234A;
  border-top: 8vw solid transparent;
  border-bottom: 7vw solid transparent;
  align-content: center;
  align-items: center;
}
}