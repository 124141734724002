#foto_360{
  display: none;
}


.btn__Agregar__habtacion {
  width: 50%;
  border: none;
  margin-top: 2px;
  background: purple;
  color: white;
  border-radius: 5px;
  font-size: 1em;
}

#foto_perfil_modelo_update{
  display: none;
}

.img360{
  width: 200px;

  position: absolute;
  object-fit: contain;
  top: 27%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.div__btn__rooms__update{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}