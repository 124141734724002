.btn_turnos_ver{
    width: 3rem;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
}

.flex_div_rooms_ver{
    display: flex;
}

.btn__entregar {
    color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 8px 2px 5px;
    cursor: pointer;
}