@font-face {
  font-family: "font-page";
  src: url('./Champagne&Limousines.ttf');
  }
body{
  margin: 0;
  padding: 0;
  background: white;
  font-family: "font-page";
  
}
 
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}



@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
}
}
.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}


.fade-out {
  animation: fadeOut ease 1s;
  -webkit-animation: fadeOut ease 1s;
  -moz-animation: fadeOut ease 1s;
  -o-animation: fadeOut ease 1s;
  -ms-animation: fadeOut ease 1s;
}

.react-date-picker__wrapper, .react-datetime-picker__wrapper {
  border-style: hidden !important;
  background-color: white;
  border-radius: 5px;
}

.react-datetime-picker{
  width: 100%;
}

.centrar_verticalmente{
  display: flex;
  align-items: center;
}
.text__rigth{
  text-align: right;
}

.popups-alert{
  background-color: #d4edda;
  border-color: #c3e6cb;
  position: fixed;
  bottom: 0;
  right: -100px;
  width: 20%;
  height: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: #C6C6C6;
  border-radius: 1rem;
  transition: 1s;
  right: 0;
}

.popups-alert-inside{
  background: #FFFFFF;
  border-radius: 1rem;
    width: 90%;
    height: 59%;
    margin-top: 16%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.success-text{
  color: green;
}

.error-text{
  color: red;
}
.alert-img{
  position: absolute;
  top: -3rem;
  width: 6.5rem;
}
@media only screen and (max-width: 600px) {

table {
  display: block;
  overflow-x: auto;
}


.reporte__div__principal {
  background-color: rgba(200, 200, 200, 0.3);
  margin: 10px !important;
  margin-top: 0px;
  border-radius: 25px;
}

}

