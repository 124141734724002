.nombre__reporte__container_porcentaje{
    display: flex;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #C8C8C8;
    margin-top: 15px;
}

.Boton__reportes__porcentajes__modelos {
    width: 100%;
    border: none;
    margin-top: 2px;
    background: purple;
    color: white;
    border-radius: 5px;
    font-size: 1em;
}

.porcentajesInput{
    width: 15%;
    margin-top: 2%;
    text-align: center;
    margin-left: 5px;
    font-size: 1vw;
}
.text__cantidad{
font-size: 1vw;
margin-top: 2%;
}

.porcentajesInput__Cantidad{
    width: 15%;
    text-align: center;
    margin-left: 5px;
    font-size: 1vw;

}

.third__col__porcentaje{
    display: grid;
    grid-template-columns: 20% auto;
    grid-gap: 15px;
}
.third__col__porcentaje img {
    margin-top: 68px;
    width: 33rem;
}
.text_right{
    text-align: right;
    margin-top: 35px;
}

.text__div__porcentaje{
    position: relative;
    color: #828282;
    margin-top: 80px;
    font-size: 1.4em;
    font-weight: 700;
}

//progreesbar
.progress_bar_porcentaje {
    position: absolute;
    width: 26.9em;
    top: 7.2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color:none;
    transition: width .6s ease;
}

.progress_bar_porcentaje2 {
    position: absolute;
    width: 26.9em;
    top: 10.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color:none;
    transition: width .6s ease;
}

.progress_bar_porcentaje3 {
    position: absolute;
    width: 26.9em;
    top: 13.6em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color:none;
    transition: width .6s ease;
}

.text__margin{
    margin-top: 20px;

}
.simbolo__dinero__porcentaje__div{
    font-size: 5rem;
    position: absolute;
    right: 0;
    top: -78px;
    color: #C8C8C8;
}
.titulo_porcentaje{
    font-size: 2vw;
    color:#828282;
}

 
 .progress_bar_porcentaje > div {
    color: white;
    overflow: hidden;
    white-space: nowrap;
    padding: 10px 20px;
    height: 2rem;
    -webkit-animation: progress-bar 2s;
    -moz-animation: progress-bar 2s;
 } 
 .progress_bar_porcentaje2 > div {
    color: white;
    overflow: hidden;
    white-space: nowrap;
    padding: 10px 20px;
    height: 2rem;
    -webkit-animation: progress-bar 2s;
    -moz-animation: progress-bar 2s;
 } 
 .progress_bar_porcentaje3 > div {
    color: white;
    overflow: hidden;
    white-space: nowrap;
    padding: 10px 20px;
    height: 2rem;
    -webkit-animation: progress-bar 2s;
    -moz-animation: progress-bar 2s;
 } 
 
 @-webkit-keyframes progress-bar {
   0% { width: 0; }
 } 
 
 @-moz-keyframes progress-bar {
    0% { width: 0; }
 }

 .btn__porcentaje_page {
    width: 41%;
    border: none;
    margin-top: 62px;
    background: purple;
    color: white;
    border-radius: 5px;
    float: right;
    font-size: 1em;
}