.cardcuentamodelo{
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.Creteaccountmodelcard{
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: white;
    width: 60%;
    margin-top: 2%;
    text-align: center;
    font-size: 1rem;
    border-radius: 25px;
}

.textaccountmodelcard{
    /* border-style: outset; */
    background: white;
    border: 0 none;
    border-radius: 0px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
    padding: 20px 30px;
    box-sizing: border-box;
    width: 60%;
    margin: 0.5%;
}

.center{
    text-align: center;
}

.btnsaveaccountmodelcard{
    background: none;
    margin: 5px;
    background-color: purple;
    color: white;
    border: 0 none;
    border-radius: 0px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
    padding: 10px 10px;
    box-sizing: border-box;
    width: 34%;
    margin: 1.5%;
    font-size: 1rem;
   &[disabled] {
    opacity: .3;
  }
}

.cardsmaindiv{

    display: grid;
    grid-template: repeat(3, auto) / repeat(3, 1fr);
    grid-gap: 5px;
}

.cardcuentasmodelo{

    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: white;
    width: 90%;
    margin-top: 2%;
    text-align: center;
    font-size: 1rem;
    border-radius: 25px;
}
.btnactualizacorreo {
    background: none;
    margin: 5px;
    background-color: purple;
    color: white;
    border: 0 none;
    border-radius: 0px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
    padding: 10px 10px;
    box-sizing: border-box;
    width: 100%;
    /* margin: 1.5%; */
    font-size: 1rem;
}