.btn__reportes__div__pagos{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.img__fecha_pagos{

  width: 11vh;
  position: relative;
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.paddingPagos{
padding: 40px;
}
